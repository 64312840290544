<template>
  <div>
    <p>{{ cat }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cat: [],
    };
  },
  mounted() {
    this.cat = {
      Message: "Top Category List",
      Data: [
        {
          catId: 79,
          MobBannerImgUrl: null,
          catName: "FIFA World Cup 2022",
          image: "",
          parentId: 1,
          code: "1#79",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy FIFA World Cup 2022 Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl: null,
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online FIFA World Cup 2022 At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "fifa-world-cup-2022",
          imageUrl:
            "assets/images/uploads/productimages/FIFAWorldCup202220220928T092359.tif",
          delDate: null,
        },
        {
          catId: 10,
          MobBannerImgUrl: null,
          catName: "DSLR Lenses",
          image: "",
          parentId: 2,
          code: "1#2#10",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy DSLR Lenses Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl: null,
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online DSLR Lenses At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "dslr-lenses",
          imageUrl:
            "assets/images/uploads/productimages/DSLRLenses20220804T103030.png",
          delDate: null,
        },
        {
          catId: 1,
          MobBannerImgUrl: null,
          catName: "Root Category",
          image: "fab fa-accessible-icon",
          parentId: 0,
          code: "1",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Root Category Online in Doha, Qatar",
          metaKeywords: "Buy Root Category Online in Qatar",
          bannerImgUrl: null,
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Root Category At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "rootcategory",
          imageUrl: null,
          delDate: null,
        },
        {
          catId: 3,
          MobBannerImgUrl: null,
          catName: "360&Action Camera",
          image: " fab fa-accessible-icon",
          parentId: 1,
          code: "1#3",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy 360&Action Camera Online in Doha, Qatar",
          metaKeywords: "Buy ACTION CAMERA Online in  Doha, Qatar",
          bannerImgUrl: null,
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online 360&Action Camera At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "360action-camera",
          imageUrl:
            "assets/images/uploads/productimages/360ActionCamera20220808T175901.jpg",
          delDate: null,
        },
        {
          catId: 4,
          MobBannerImgUrl: null,
          catName: "360 & Action Camera Accessories",
          image: " fab fa-accessible-icon",
          parentId: 55,
          code: "1#55#4",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle:
            "Buy 360 & Action Camera Accessories Online in Doha, Qatar",
          metaKeywords: "Buy ACTION CAMERA ACCESSORIES Online in  Doha, Qatar",
          bannerImgUrl: null,
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online 360 & Action Camera Accessories At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "360--action-camera-accessories",
          imageUrl:
            "assets/images/uploads/productimages/ACTIONCAMERAACCESSORIES20220725T091624.jpg",
          delDate: null,
        },
        {
          catId: 6,
          MobBannerImgUrl: null,
          catName: "DSLR cameras",
          image: " fab fa-accessible-icon",
          parentId: 16,
          code: "1#16#6",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy DSLR cameras Online in Doha, Qatar",
          metaKeywords: "Buy DSLR CAMERA'S Online in  Doha, Qatar",
          bannerImgUrl: null,
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online DSLR cameras At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "dslr-camerasF537CCA",
          imageUrl:
            "assets/images/uploads/productimages/DSLRcameras20220808T175045.jpg",
          delDate: null,
        },
        {
          catId: 7,
          MobBannerImgUrl: null,
          catName: "Microphone",
          image: " fab fa-accessible-icon",
          parentId: 18,
          code: "1#53#18#7",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Microphone Online in Doha, Qatar",
          metaKeywords: "Buy MICROPHONES Online in  Doha, Qatar",
          bannerImgUrl: null,
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Microphone At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "microphone",
          imageUrl: "assets/images/uploads/productimages/no-image.jpg",
          delDate: null,
        },
        {
          catId: 26,
          MobBannerImgUrl: null,
          catName: "Video Lighting",
          image: "",
          parentId: 25,
          code: "1#25#26",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Video Lighting Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl:
            "assets/images/uploads/productimages/BannerVideoLighting20220812T183125.jpg",
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Video Lighting At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "video-lighting",
          imageUrl:
            "assets/images/uploads/productimages/VideoLighting20220808T183110.jpg",
          delDate: null,
        },
        {
          catId: 16,
          MobBannerImgUrl: null,
          catName: "Camera",
          image: "",
          parentId: 1,
          code: "1#16",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Camera Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl: null,
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Camera At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "camera",
          imageUrl: "",
          delDate: null,
        },
        {
          catId: 37,
          MobBannerImgUrl: null,
          catName: "Filters",
          image: "",
          parentId: 55,
          code: "1#55#37",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Filters Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl: null,
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Filters At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "filters",
          imageUrl:
            "assets/images/uploads/productimages/Filters20220827T183138.jpg",
          delDate: null,
        },
        {
          catId: 44,
          MobBannerImgUrl: null,
          catName: "Podcasting ",
          image: "",
          parentId: 18,
          code: "1#18#44",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Podcasting  Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl: null,
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Podcasting  At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "podcasting",
          imageUrl: "",
          delDate: null,
        },
        {
          catId: 27,
          MobBannerImgUrl: null,
          catName: "Photo Lighting",
          image: "",
          parentId: 25,
          code: "1#25#27",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Photo Lighting Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl:
            "assets/images/uploads/productimages/BannerPhotoLighting20220811T190841.jpg",
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Photo Lighting At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "photo-lighting",
          imageUrl:
            "assets/images/uploads/productimages/PhotoLighting20220811T171322.jpg",
          delDate: null,
        },
        {
          catId: 8,
          MobBannerImgUrl: null,
          catName: "Microphone Accessories",
          image: " fab fa-accessible-icon",
          parentId: 18,
          code: "1#18#8",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Microphone Accessories Online in Doha, Qatar",
          metaKeywords: "Buy MICROPHONES ACCESSORIES Online in  Doha, Qatar",
          bannerImgUrl: null,
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Microphone Accessories At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "microphone-accessories",
          imageUrl: "assets/images/uploads/productimages/no-image.jpg",
          delDate: null,
        },
        {
          catId: 2,
          MobBannerImgUrl: null,
          catName: "Lenses",
          image: " fab fa-accessible-icon",
          parentId: 1,
          code: "1#2",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Lenses Online in Doha, Qatar",
          metaKeywords: "Buy LENS Online in  Doha, Qatar",
          bannerImgUrl: null,
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Lenses At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "lenses",
          imageUrl:
            "assets/images/uploads/productimages/LENS20220725T091722.jpg",
          delDate: null,
        },
        {
          catId: 11,
          MobBannerImgUrl: null,
          catName: "Mirrorless Lenses",
          image: "",
          parentId: 2,
          code: "1#2#11",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Mirrorless Lenses Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl: null,
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Mirrorless Lenses At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "mirrorless-lenses",
          imageUrl:
            "assets/images/uploads/productimages/MirrorlessLenses20220804T103146.png",
          delDate: null,
        },
        {
          catId: 55,
          MobBannerImgUrl: null,
          catName: "Accessories",
          image: "",
          parentId: 1,
          code: "1#55",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Accessories Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl:
            "assets/images/uploads/productimages/BannerAccessories20220813T151508.jpg",
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Accessories At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "accessories",
          imageUrl:
            "assets/images/uploads/productimages/Accessories20220813T144258.jpg",
          delDate: null,
        },
        {
          catId: 64,
          MobBannerImgUrl: null,
          catName: "Tripods",
          image: "",
          parentId: 62,
          code: "1#62#64",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Tripods Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl:
            "assets/images/uploads/productimages/BannerTripods20220814T185735.jpg",
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Tripods At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "tripods",
          imageUrl:
            "assets/images/uploads/productimages/Tripods20220814T185735.jpg",
          delDate: null,
        },
        {
          catId: 74,
          MobBannerImgUrl: null,
          catName: "Mobile Microphones",
          image: "",
          parentId: 36,
          code: "1#36#74",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Mobile Microphones Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl: null,
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Mobile Microphones At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "mobile-microphones",
          imageUrl: "",
          delDate: null,
        },
        {
          catId: 75,
          MobBannerImgUrl: null,
          catName: "Mobile Lighting",
          image: "",
          parentId: 36,
          code: "1#36#75",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Mobile Lighting Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl: null,
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Mobile Lighting At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "mobile-lighting",
          imageUrl: "",
          delDate: null,
        },
        {
          catId: 65,
          MobBannerImgUrl: null,
          catName: "Monopods",
          image: "",
          parentId: 62,
          code: "1#62#65",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Monopods Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl:
            "assets/images/uploads/productimages/BannerMonopods20220815T090121.jpg",
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Monopods At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "monopods",
          imageUrl:
            "assets/images/uploads/productimages/Monopods20220815T090121.jpg",
          delDate: null,
        },
        {
          catId: 56,
          MobBannerImgUrl: null,
          catName: "Monitors & Recoders",
          image: "",
          parentId: 53,
          code: "1#53#56",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Monitors & Recoders Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl:
            "assets/images/uploads/productimages/BannerMonitorsRecoders20220814T072437.jpg",
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Monitors & Recoders At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "monitors--recoders",
          imageUrl:
            "assets/images/uploads/productimages/MonitorsRecoders20220814T072437.jpg",
          delDate: null,
        },
        {
          catId: 9,
          MobBannerImgUrl: null,
          catName: "Mirrorless Cameras",
          image: " fab fa-accessible-icon",
          parentId: 16,
          code: "1#16#9",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Mirrorless Cameras Online in Doha, Qatar",
          metaKeywords: "Buy MIRRORLESS CAMERA Online in  Doha, Qatar",
          bannerImgUrl: null,
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Mirrorless Cameras At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "mirrorless-cameras",
          imageUrl:
            "assets/images/uploads/productimages/MirrorlessCameras20220808T174531.jpg",
          delDate: null,
        },
        {
          catId: 28,
          MobBannerImgUrl: null,
          catName: "On-Camera Lighting",
          image: "",
          parentId: 25,
          code: "1#25#28",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy On-Camera Lighting Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl:
            "assets/images/uploads/productimages/BannerOnCameraLighting20220811T185744.jpg",
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online On-Camera Lighting At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "oncamera-lighting",
          imageUrl:
            "assets/images/uploads/productimages/OnCameraLighting20220811T185916.jpg",
          delDate: null,
        },
        {
          catId: 38,
          MobBannerImgUrl: null,
          catName: "Charger's",
          image: "",
          parentId: 55,
          code: "1#55#38",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Charger's Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl: null,
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Charger's At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "chargers",
          imageUrl:
            "assets/images/uploads/productimages/GeneralAccessories20220804T115058.png",
          delDate: null,
        },
        {
          catId: 18,
          MobBannerImgUrl: null,
          catName: "PRO AUDIO ",
          image: "",
          parentId: 53,
          code: "1#53#18",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy PRO AUDIO  Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl:
            "assets/images/uploads/productimages/BannerPROAUDIO20220813T165549.jpg",
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online PRO AUDIO  At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "pro-audio",
          imageUrl:
            "assets/images/uploads/productimages/PROAUDIO20220813T165549.jpg",
          delDate: null,
        },
        {
          catId: 20,
          MobBannerImgUrl: null,
          catName: "Point & Shoot Cameras",
          image: "",
          parentId: 16,
          code: "1#16#20",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Point & Shoot Cameras Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl: null,
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Point & Shoot Cameras At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "point--shoot-cameras",
          imageUrl:
            "assets/images/uploads/productimages/PointShootCameras20220804T114634.png",
          delDate: null,
        },
        {
          catId: 45,
          MobBannerImgUrl: null,
          catName: "Wireless Microphones",
          image: "",
          parentId: 18,
          code: "1#18#45",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Wireless Microphones Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl: null,
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Wireless Microphones At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "wireless-microphones",
          imageUrl: "",
          delDate: null,
        },
        {
          catId: 5,
          MobBannerImgUrl: null,
          catName: "Lens Adapters",
          image: " fab fa-accessible-icon",
          parentId: 2,
          code: "1#2#5",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Lens Adapters Online in Doha, Qatar",
          metaKeywords: "Buy CAMERA MOUNT ADAPTER Online in  Doha, Qatar",
          bannerImgUrl: null,
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Lens Adapters At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "lens-adapters",
          imageUrl:
            "assets/images/uploads/productimages/LensAdapters20220804T124509.png",
          delDate: null,
        },
        {
          catId: 76,
          MobBannerImgUrl: null,
          catName: "Mobile Lenses & Cases",
          image: "",
          parentId: 36,
          code: "1#36#76",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Mobile Lenses & Cases Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl: null,
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Mobile Lenses & Cases At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "mobile-lenses--cases",
          imageUrl: "",
          delDate: null,
        },
        {
          catId: 25,
          MobBannerImgUrl: null,
          catName: "Lightning & Studio",
          image: "",
          parentId: 1,
          code: "1#25",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Lightning & Studio Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl: null,
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Lightning & Studio At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "lightning--studio",
          imageUrl: "",
          delDate: null,
        },
        {
          catId: 73,
          MobBannerImgUrl: null,
          catName: "Mobile Gimbals",
          image: "",
          parentId: 1,
          code: "1#73",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Mobile Gimbals Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl:
            "assets/images/uploads/productimages/BannerMobileGimbals20220815T191139.jpg",
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Mobile Gimbals At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "mobile-gimbals",
          imageUrl:
            "assets/images/uploads/productimages/MobileGimbals20220809T192015.jpg",
          delDate: null,
        },
        {
          catId: 58,
          MobBannerImgUrl: null,
          catName: "Wireless Transimission",
          image: "",
          parentId: 53,
          code: "1#53#58",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Wireless Transimission Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl:
            "assets/images/uploads/productimages/BannerWirelessTransimission20220814T075636.jpg",
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Wireless Transimission At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "wireless-transimission",
          imageUrl:
            "assets/images/uploads/productimages/WirelessTransimission20220814T075636.jpg",
          delDate: null,
        },
        {
          catId: 53,
          MobBannerImgUrl: null,
          catName: "Pro Video & Audio",
          image: "",
          parentId: 1,
          code: "1#53",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Pro Video & Audio Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl: null,
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Pro Video & Audio At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "pro-video--audio",
          imageUrl: "",
          delDate: null,
        },
        {
          catId: 54,
          MobBannerImgUrl: null,
          catName: "Gimbals",
          image: "",
          parentId: 1,
          code: "1#54",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Gimbals Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl:
            "assets/images/uploads/productimages/BannerGimbals20220813T143948.jpg",
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Gimbals At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "gimbals",
          imageUrl:
            "assets/images/uploads/productimages/Gimbals20220813T092318.jpg",
          delDate: null,
        },
        {
          catId: 46,
          MobBannerImgUrl: null,
          catName: "Music Production",
          image: "",
          parentId: 18,
          code: "1#18#46",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Music Production Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl: null,
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Music Production At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "music-production",
          imageUrl: "",
          delDate: null,
        },
        {
          catId: 21,
          MobBannerImgUrl: null,
          catName: "Instant Cameras",
          image: "",
          parentId: 16,
          code: "1#16#21",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Instant Cameras Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl: null,
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Instant Cameras At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "instant-cameras",
          imageUrl:
            "assets/images/uploads/productimages/InstantCameras20220808T174058.jpg",
          delDate: null,
        },
        {
          catId: 40,
          MobBannerImgUrl: null,
          catName: "Storage & Media",
          image: "",
          parentId: 55,
          code: "1#55#40",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Storage & Media Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl: null,
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Storage & Media At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "storage--media",
          imageUrl:
            "assets/images/uploads/productimages/StorageMedia20220804T115012.png",
          delDate: null,
        },
        {
          catId: 41,
          MobBannerImgUrl: null,
          catName: "Cage & Support ",
          image: "",
          parentId: 55,
          code: "1#55#41",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Cage & Support  Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl: null,
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Cage & Support  At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "cage--support",
          imageUrl:
            "assets/images/uploads/productimages/CageSupport20220804T114957.png",
          delDate: null,
        },
        {
          catId: 31,
          MobBannerImgUrl: null,
          catName: "Ring Lights",
          image: "",
          parentId: 25,
          code: "1#25#31",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Ring Lights Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl:
            "assets/images/uploads/productimages/BannerRingLights20220811T182537.jpg",
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Ring Lights At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "ring-lights",
          imageUrl:
            "assets/images/uploads/productimages/RingLights20220811T182537.jpg",
          delDate: null,
        },
        {
          catId: 15,
          MobBannerImgUrl: null,
          catName: "Lens Accessories",
          image: "",
          parentId: 2,
          code: "1#2#15",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Lens Accessories Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl: null,
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Lens Accessories At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "lens-accessories",
          imageUrl:
            "assets/images/uploads/productimages/LensAccessories20220804T102947.png",
          delDate: null,
        },
        {
          catId: 68,
          MobBannerImgUrl: null,
          catName: "Hard Cases",
          image: "",
          parentId: 62,
          code: "1#62#68",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Hard Cases Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl:
            "assets/images/uploads/productimages/BannerHardCases20220815T153250.jpg",
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Hard Cases At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "hard-cases",
          imageUrl:
            "assets/images/uploads/productimages/HardCases20220815T153250.jpg",
          delDate: null,
        },
        {
          catId: 62,
          MobBannerImgUrl: null,
          catName: "Tripods & Bags",
          image: "",
          parentId: 1,
          code: "1#62",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Tripods & Bags Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl: null,
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Tripods & Bags At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "tripods--bags",
          imageUrl: "",
          delDate: null,
        },
        {
          catId: 69,
          MobBannerImgUrl: null,
          catName: "Tripod & Bag's Accessories ",
          image: "",
          parentId: 62,
          code: "1#62#69",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Tripod & Bag's Accessories  Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl:
            "assets/images/uploads/productimages/BannerTripodBagsAccessories20220816T190621.jpg",
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Tripod & Bag's Accessories  At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "tripod--bags-accessories",
          imageUrl:
            "assets/images/uploads/productimages/TripodBagsAccessories20220816T190621.jpg",
          delDate: null,
        },
        {
          catId: 60,
          MobBannerImgUrl: null,
          catName: "Live Streaming",
          image: "",
          parentId: 53,
          code: "1#53#60",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Live Streaming Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl:
            "assets/images/uploads/productimages/BannerLiveStreaming20220814T094908.jpg",
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Live Streaming At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "live-streaming",
          imageUrl:
            "assets/images/uploads/productimages/LiveStreaming20220814T094908.jpg",
          delDate: null,
        },
        {
          catId: 32,
          MobBannerImgUrl: null,
          catName: "Table Top Shooting",
          image: "",
          parentId: 25,
          code: "1#25#32",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Table Top Shooting Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl:
            "assets/images/uploads/productimages/BannerTableTopShooting20220811T191647.jpg",
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Table Top Shooting At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "table-top-shooting",
          imageUrl:
            "assets/images/uploads/productimages/TableTopShooting20220811T192012.jpg",
          delDate: null,
        },
        {
          catId: 42,
          MobBannerImgUrl: null,
          catName: "Batteries & Power",
          image: "",
          parentId: 55,
          code: "1#55#42",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Batteries & Power Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl: null,
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Batteries & Power At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "batteries--power",
          imageUrl:
            "assets/images/uploads/productimages/BatteriesPower20220804T114936.png",
          delDate: null,
        },
        {
          catId: 48,
          MobBannerImgUrl: null,
          catName: "Headphones & Speakers",
          image: "",
          parentId: 18,
          code: "1#18#48",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Headphones & Speakers Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl: null,
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Headphones & Speakers At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "headphones--speakers",
          imageUrl: "",
          delDate: null,
        },
        {
          catId: 49,
          MobBannerImgUrl: null,
          catName: "Mixers & Recorders",
          image: "",
          parentId: 18,
          code: "1#18#49",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Mixers & Recorders Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl: null,
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Mixers & Recorders At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "mixers--recorders",
          imageUrl: "",
          delDate: null,
        },
        {
          catId: 43,
          MobBannerImgUrl: null,
          catName: "Cables & Adapters",
          image: "",
          parentId: 55,
          code: "1#55#43",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Cables & Adapters Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl: null,
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Cables & Adapters At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "cables--adapters",
          imageUrl:
            "assets/images/uploads/productimages/CablesAdapters20220804T114918.png",
          delDate: null,
        },
        {
          catId: 33,
          MobBannerImgUrl: null,
          catName: "Backgrounds",
          image: "",
          parentId: 1,
          code: "1#33",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Backgrounds Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl:
            "assets/images/uploads/productimages/BannerBackgrounds20220811T192627.jpg",
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Backgrounds At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "backgrounds",
          imageUrl:
            "assets/images/uploads/productimages/Backgrounds20220811T192728.jpg",
          delDate: null,
        },
        {
          catId: 61,
          MobBannerImgUrl: null,
          catName: "Sliders & Jibs",
          image: "",
          parentId: 53,
          code: "1#53#61",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Sliders & Jibs Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl:
            "assets/images/uploads/productimages/BannerSlidersJibs20220814T102324.jpg",
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Sliders & Jibs At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "sliders--jibs",
          imageUrl:
            "assets/images/uploads/productimages/SlidersJibs20220814T102324.jpg",
          delDate: null,
        },
        {
          catId: 71,
          MobBannerImgUrl: null,
          catName: "Bags,Cases&Pouches",
          image: "",
          parentId: 62,
          code: "1#62#71",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Bags,Cases&Pouches Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl:
            "assets/images/uploads/productimages/BannerBagsCasesPouches20220822T142444.jpg",
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Bags,Cases&Pouches At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "bagscasespouches",
          imageUrl:
            "assets/images/uploads/productimages/BagsCasesPouches20220820T150602.jfif",
          delDate: null,
        },
        {
          catId: 77,
          MobBannerImgUrl: null,
          catName: "Mobile Accessories",
          image: "",
          parentId: 55,
          code: "1#55#77",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Mobile Accessories Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl:
            "assets/images/uploads/productimages/BannerMobileAccessories20220815T160354.jpg",
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Mobile Accessories At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "mobile-accessories",
          imageUrl:
            "assets/images/uploads/productimages/MobileAccessories20220808T173223.png",
          delDate: null,
        },
        {
          catId: 80,
          MobBannerImgUrl: null,
          catName: "SCREW'S",
          image: "",
          parentId: 55,
          code: "1#55#80",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: null,
          metaKeywords: null,
          bannerImgUrl: null,
          ShowInOfferZone: false,
          metaDescription: null,
          catUrlKey: "screws",
          imageUrl:
            "assets/images/uploads/productimages/SCREWS20221018T160415.jpg",
          delDate: null,
        },
        {
          catId: 34,
          MobBannerImgUrl: null,
          catName: "Light Stands & Grips",
          image: "",
          parentId: 25,
          code: "1#25#34",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Light Stands & Grips Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl:
            "assets/images/uploads/productimages/BannerLightStandsGrips20220812T185915.jpg",
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Light Stands & Grips At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "light-stands--grips",
          imageUrl:
            "assets/images/uploads/productimages/LightStandsGrips20220812T185825.jpg",
          delDate: null,
        },
        {
          catId: 50,
          MobBannerImgUrl: null,
          catName: "Shotguns",
          image: "",
          parentId: 18,
          code: "1#18#50",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Shotguns Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl: null,
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Shotguns At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "shotguns",
          imageUrl: "",
          delDate: null,
        },
        {
          catId: 51,
          MobBannerImgUrl: null,
          catName: "Audio Accessories",
          image: "",
          parentId: 18,
          code: "1#18#51",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Audio Accessories Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl: null,
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Audio Accessories At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "audio-accessories",
          imageUrl: "",
          delDate: null,
        },
        {
          catId: 52,
          MobBannerImgUrl: null,
          catName: "Audio Interfaces",
          image: "",
          parentId: 18,
          code: "1#18#52",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Audio Interfaces Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl: null,
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Audio Interfaces At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "audio-interfaces",
          imageUrl: "",
          delDate: null,
        },
        {
          catId: 35,
          MobBannerImgUrl: null,
          catName: "Lighting Acessories",
          image: "",
          parentId: 25,
          code: "1#25#35",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Lighting Acessories Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl:
            "assets/images/uploads/productimages/BannerLightingAcessories20220813T075909.jpg",
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Lighting Acessories At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "lighting-acessories",
          imageUrl:
            "assets/images/uploads/productimages/LightingAcessories20220813T075909.jpg",
          delDate: null,
        },
        {
          catId: 72,
          MobBannerImgUrl: null,
          catName: "Protection",
          image: "",
          parentId: 62,
          code: "1#62#72",
          description: null,
          ProductCount: null,
          urlKey: null,
          metaTitle: "Buy Protection Online in Doha, Qatar",
          metaKeywords: null,
          bannerImgUrl: null,
          ShowInOfferZone: false,
          metaDescription:
            "Shop Online Protection At Best Price from LenzCamera Qatar.  Home delivery & Online Payments available ",
          catUrlKey: "protection",
          imageUrl:
            "assets/images/uploads/productimages/Protection20220820T150943.jpg",
          delDate: null,
        },
      ],
    };

    this.spilt();
  },
  methods: {
    spilt() {
      for (let i = 0; i < this.cat.Data.length; i++) {
        var item = this.cat.Data[i];
        // console.log(item);
        var code = item.code,
          parts = code.split("#");

        if (parts.length == 2) {
          console.log(parts);
           console.log(item.imageUrl);
        }
      }

      for (let i = 0; i < this.cat.Data.length; i++) {
        var item2 = this.cat.Data[i];
        var code2 = item2.code,
          parts2 = code2.split("#");

        if (parts2.length == 3) {
          console.log(parts2);
          console.log(item2);
        }
      }
      for (let i = 0; i < this.cat.Data.length; i++) {
        var item3 = this.cat.Data[i];
        var code3 = item3.code,
          parts3 = code3.split("#");

        if (parts3.length == 4) {
          console.log(parts3 );
          console.log(item3 );
         
        }
      }
    },
  },
};
</script>
